<template>
    <div class="p-t-5 p-l-5">
        <Divider dashed style="margin: 10px 0 5px 0;"><span class="divider-text">数据汇总</span></Divider>

        <p>
            <span class="title">汇总时间：</span>
            <span v-if="maintenanceReconciliationQuery.startDate === ''">全部</span>
            <span v-else>
              {{maintenanceReconciliationQuery.startDate}} 至 {{maintenanceReconciliationQuery.endDate}}
            </span>
        </p>
        <Row class="text-center m-t-10 p-t-5 p-b-5 transaction-content">
            <i-col span="24" class="text-16 p-b-10">
               已质检验收总额：<span class="text-orange">{{formatMoney(executeAmout.checkAmount)}}</span>
            </i-col>
            <i-col span="12">
                <p class="title">未质检验收总额</p>
                {{formatMoney(executeAmout.notCheckAmount)}}
            </i-col>
            <i-col span="12">
                <p class="title">全部金额</p>
                {{formatMoney(executeAmout.totalAmount)}}
            </i-col>
        </Row>

        <div v-for="(settlement,i) in executeAmout.settlementInfoVOList" :key="i">
          <p class="m-t-20">
              <span class="title">公司：</span>
              {{settlement.settlementCompanyName}}
          </p>
          <Row class="table-title p-t-2">
              <i-col span="8">供应商</i-col>
              <i-col span="8">已质检验收</i-col>
              <i-col span="8">未质检验收</i-col>
          </Row>
          <Row v-for="(supplier,j) in settlement.supplierInfoVOList" :key="'s0'+j" class="p-t-2">
              <i-col span="8">{{supplier.supplierCompanyName}}</i-col>
              <i-col span="8">{{formatMoney(supplier.checkAmount)}}</i-col>
              <i-col span="8" class="money">{{formatMoney(supplier.notCheckAmount)}}</i-col>
          </Row>
          <Divider style="margin: 0;"></Divider>
          <Row class="p-t-2">
              <i-col span="8">合计</i-col>
              <i-col span="8" class="text-orange">{{formatMoney(settlement.checkAmount)}}</i-col>
              <i-col span="8" class="money">{{formatMoney(settlement.notCheckAmount)}}</i-col>
          </Row>
        </div>

    </div>
</template>

<script>
import { getsettlementcoststatistics } from '@/api/msp/settlement'
import { toMoney } from '@/utils/wnumb_own'

export default {
  data () {
    return {
      query: {
        settlementCompanyId: 0,
        publisherId: this.$store.getters.token.userInfo.publisherId,
        startDate: '',
        endDate: '',
        status: null,
        keyword: ''
      },
      executeAmout: {}
    }
  },
  created () {
    // this.initPageData()
  },
  methods: {
    initPageData () {
      getsettlementcoststatistics(this.query).then(res => {
        this.executeAmout = res
      })
    },
    formatMoney (number) {
      return toMoney(number)
    }
  },
  computed: {
    beginUpdate () {
      return this.$store.state.supplierReconciliation.beginUpdate
    },
    maintenanceReconciliationQuery () {
      return this.$store.state.supplierReconciliation.maintenanceReconciliationQuery
    }
  },
  watch: {
    beginUpdate () {
      if (this.maintenanceReconciliationQuery.supplierCompanyId) {
        this.query.supplierCompanyId = this.maintenanceReconciliationQuery.supplierCompanyId
        this.query.settlementCompanyId = this.maintenanceReconciliationQuery.settlementCompanyId
        this.query.startDate = this.maintenanceReconciliationQuery.startDate
        this.query.endDate = this.maintenanceReconciliationQuery.endDate
        this.query.startActionDate = this.maintenanceReconciliationQuery.startActionDate
        this.query.endActionDate = this.maintenanceReconciliationQuery.endActionDate
        this.query.keyword = this.maintenanceReconciliationQuery.keyword
        this.query.minCost = this.maintenanceReconciliationQuery.minCost
        this.query.maxCost = this.maintenanceReconciliationQuery.maxCost
        this.query.taskMainTypeFlag = this.maintenanceReconciliationQuery.taskMainTypeFlag
        this.query.status = this.maintenanceReconciliationQuery.status === -1 ? null : this.maintenanceReconciliationQuery.status
        this.query.taskitemTypes = this.maintenanceReconciliationQuery.taskitemTypes
        this.query.resourcetypeIds = this.maintenanceReconciliationQuery.resourcetypeIds
      }

      this.initPageData()
    }
  }
}
</script>
